import { useContext } from 'react';
import Image from 'next/image';
import { AmplienceImageCard } from '@Types/amplience/amplienceComponents';
import AmplienceButton from 'components/frontastic-ui/amplience/amplience-button';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './notFound404.module.scss';

interface NotFoundData {
  description: string;
  imageCard: AmplienceImageCard;
  buttons: any;
}

export interface NotFound404Props {
  content: NotFoundData;
}

const NotFound404: React.FC<NotFound404Props> = ({ content }) => {
  const layout = useContext(LayoutContext);
  const descAndButtons = (
    <div>
      <h2 className={styles.description} data-testid="not-found-description">
        {content?.description}
      </h2>
      {content?.buttons && (
        <div className={styles.buttonsWrapper}>
          {content.buttons.map((button) => (
            <AmplienceButton content={button} key={`btn-${button.content?.link}`} />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.mainContainer} data-testid="not-found-main-container">
      <section className={styles.wordingSections}>
        <h1 className={styles.title}>404</h1>
        {!layout.isMobile && descAndButtons}
      </section>
      <section className={styles.imageSection}>
        {content?.imageCard && (
          <Image
            src={createImageUrlFromAmplienceImageCard(content.imageCard)}
            priority={true}
            className={styles.image}
            loader={({ src, width }) => amplienceImageLoader('General', src, width)}
            sizes="(max-width: 768px) 100vw, 50vw"
            alt={content?.imageCard?.metaTextAleternative}
            layout="fill"
            data-testid="not-found-image"
          />
        )}
      </section>
      {layout.isMobile && descAndButtons}
    </div>
  );
};

export default NotFound404;
