import React, { useEffect, useState } from 'react';
import NotFound404 from 'components/commercetools-ui/notFound404';
import AmplienceFooter from 'components/frontastic-ui/amplience/amplience-footer';
import AmplienceHeader from 'components/frontastic-ui/amplience/amplience-header';
import { fetchContentByKey } from 'frontastic/actions/amplience';

const Error404 = () => {
  const [content, setContent] = useState();
  const [header, setHeader] = useState();
  const [footer, setFooter] = useState();

  useEffect(() => {
    fetchContentByKey(`404`).then((response) => setContent(response?.data?.content));
    fetchContentByKey(`main-header`).then((response) => setHeader(response?.data?.content));
    fetchContentByKey(`main-footer`).then((response) => setFooter(response?.data?.content));
  }, []);

  return (
    <div>
      {header && <AmplienceHeader content={header} />}
      <NotFound404 content={content} />
      {footer && <AmplienceFooter content={footer} />}
    </div>
  );
};

export default Error404;
